<script setup>
import DescriptionInline from "./DescriptionInline.vue";
import DropDownInfo from "./DropDownInfo.vue";
import DropDownInfoNumbered from "./DropDownInfoNumbered.vue";
import HeaderReport from "./HeaderReport.vue";
import FieldNameInline from "./FieldNameInline.vue";
import Header from "./Header.vue";
import api from "../api/api";
import Swal from "sweetalert2";
import ButtonSubmit from "./ButtonSubmit.vue";
import Multiselect from "@vueform/multiselect";
import { flashMessage } from "../functions.js";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import VueCookies from 'vue-cookies';
import * as XLSX from 'xlsx';
import CryptoJS from "crypto-js";
</script>

<template>
  <div class="body-container">
    <Header class="header" label="Admin Dashboard"></Header>
    <form @submit.prevent="handleSubmit">
      <div class="main-div">
        <div
          class="boxReports"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
        <div :style="{ backgroundColor: brandData.main_color1 || '#0e5071' }" class="label-div">
          <v-btn
            variant="plain"
            v-ripple="false"
            @click.prevent="toggleGospiPicker = !toggleGospiPicker"
            style="font-size: 18px; background-color: transparent; border: transparent;"
          >
            <strong>{{ toggleGospiPicker ? '—' : '+' }}</strong>
          </v-btn>
          <strong style="font-weight: bold; font-size: 18px;">Iteration Picker</strong>
        </div>
        </div>
        <div class="body-form-container" v-show="toggleGospiPicker">
          <v-row class="row-start">
            <!-- First Column - Form -->
            <v-col :cols="8">
              <form class="gospi-form">
                <!-- First Row -->
                <v-row class="form-row">
                  <v-col>
                    <v-text-field label="Organization" v-model="org_id" readonly></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select label="Select Sub-Organization" 
                    v-model="survey.suborg.suborg_id" 
                    @update:modelValue="
                    updateCookieSuborg();
                    clearData();"
                    :key="survey.suborg.suborg_id"
                    :items="filteredSubOrg"
                    :item-title="'suborg_name'"
                    :item-value="'suborg_id'"></v-select>
                  </v-col>
                </v-row>

                <!-- Second Row -->
                <v-row class="form-row">
                  <v-col>
                    <v-select label="Program" 
                    v-model="survey.program.program_id" 
                    :items="filteredProgram"
                    :item-title="'program_name'"
                    :item-value="'program_id'"
                    @update:modelValue="clearData"></v-select>
                  </v-col>
                  <v-col>
                    <v-select label="Iteration" 
                    v-model="survey.iteration.iteration_id" 
                    :items="filteredIteration"
                    :item-title="'iteration_name'"
                    :item-value="'iteration_id'"
                    @update:modelValue="
                    clearData"></v-select>
                  </v-col>
                </v-row>

                <!-- Third Row -->
                <v-row class="form-row" style="margin-top: -20px;">
                  <v-col>
                    <v-select label="Stream" 
                    v-model="survey.stream.stream_id" 
                    :items="filteredStream"
                    :item-title="'stream_name'"
                    :item-value="'stream_id'"
                    @update:modelValue="
                    clearData"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select label="Survey Template" 
                    v-model="template_id" 
                    :items="filteredSurveyTemplate"
                    :item-title="'survey_template_name'"
                    :item-value="'survey_template_id'"
                    @update:modelValue="
                    showSurveyType();
                    clearData();
                  "></v-select>
                  </v-col>
                </v-row>

                <!-- <v-row>
                  <v-col>
                    <v-radio-group v-model="showIndividualRecords" inline>
                          <v-radio :label="default_batch_action[0].label" :value="default_batch_action[0].Value"></v-radio>
                          <v-radio :label="default_batch_action[1].label" :value="default_batch_action[1].Value"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row> -->

                <!-- Submit Button -->
                <br/>
                <ButtonSubmit
                    class="search-btn"
                    label="Search"
                    @click.prevent="searchResult"
                    :disabled="disableSearchButton"
                  />
              </form>
            </v-col>
            <!-- Second Column - Statistics -->
            <v-col :cols="4">
              <!-- Right side - Statistics container -->
              <div class="box-statistics">
                <p :style="{ color: brandData.main_color1 || '#f47820' }"><strong>Statistics</strong></p>
                <clip-loader
                  v-if="survey.iteration.iteration_id != null"
                  :loading="setLoading"
                  :color="setColor"
                  :size="setSize"
                ></clip-loader>
                <div v-for="stat in statisticsData" :key="stat">
                  <div v-if="setLoading == false && survey.iteration.iteration_id != null">
                    <label class="statistics-label">Participants: {{ stat.participants_count }}</label><br />
                    <label class="statistics-label">Participants registered: {{ stat.participants_registered }} ({{ participantsRegisteredPercentage.toFixed(1) }}%)</label><br />
                    <label class="statistics-label">Participants submitted: {{ stat.participants_submitted }} ({{ participantsSubmittedPercentage.toFixed(1) }}%)</label><br />
                    <label class="statistics-label">Participants eligible for report: {{ stat.participants_eligible }} ({{ participantsEligiblePercentage.toFixed(1) }}%)</label><br />
                    <label class="statistics-label">Total nominees: {{ stat.nominees_count }} ({{ totalNomineesPercentage.toFixed(1) }} per participant)</label><br />
                    <label class="statistics-label">Total nominees submitted: {{ stat.nominees_submitted }} ({{ totalNomineesSubmittedPercentage.toFixed(1) }} per participant)</label><br />
                  </div>
                </div>
                <br />
              </div>


              
            </v-col>
          </v-row>
        </div>

        <!-- <div
          class="boxReports"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
          <div class="label-div">
            <v-btn
              variant="plain"
              v-ripple="false"
              @click.prevent="toggle2 = !toggle2"
              style="font-size: 18px; background-color: transparent; border: transparent;"
            >
              <strong>{{ toggle2 ? '—' : '+' }}</strong>
            </v-btn>
            <strong style="font-weight: bold; font-size: 18px;">Status definitions</strong>
          </div>
          <div class="box-div" v-show="toggle2" style="padding: 0 10px 0 10px">
            <br />
            <div>
              <FieldNameInline label="Not yet launched: "></FieldNameInline>
              <span
                >The assessment is in the system but has not been made available
                to the participant yet.</span
              >
              <br />
              <FieldNameInline label="Ready to start: "></FieldNameInline>
              <span
                >The assessment is available for the participant to begin.</span
              >
              <br />
              <FieldNameInline label="Started: "></FieldNameInline>
              <span>The participant has started the assessment.</span>
              <br />
              <FieldNameInline label="Open (submitted): "></FieldNameInline>
              <span
                >The assessment deadline has not been reached, but the
                individual has submitted the survey.</span
              >
              <br />
              <FieldNameInline label="Closed (submitted): "></FieldNameInline>
              <span
                >The assessment deadline has been reached, and the participant
                completed and submitted it.</span
              >
              <br />
              <FieldNameInline label="Closed (unsubmitted): "></FieldNameInline>
              <span
                >The assessment deadline has been reached, but the participant
                did not complete and submit it.</span
              >
              <br />
              <FieldNameInline label="Report released immediately: "></FieldNameInline>
              <span
                >The iteration's 'Never Run Iteration' is set to 'Yes'. 
                The assessment has a tag 'immediate_report_release', 
                this releases report to the participant and coach (assigned to the participant) 
                instantaneously after the participant submits the survey.</span
              >
              <br />
              <FieldNameInline label="Report available: "></FieldNameInline>
              <span
                >The final assessment report is available for both the
                participant and the coach (provided the participant has opted to
                share with the coach).</span
              >
              <br />
              <FieldNameInline
                label="Report available for participant: "
              ></FieldNameInline>
              <span
                >The final assessment report is available for the participant
                only.</span
              >
              <br />
              <FieldNameInline
                label="Report available for coach: "
              ></FieldNameInline>
              <span
                >The final assessment report is available for the coach
                only(provided the participant has opted to share with the
                coach).</span
              >
              <br />
              <FieldNameInline label="Report expired: "></FieldNameInline>
              <span
                >The assessment report is no longer available to the participant
                or the coach.</span
              >
              <br />
              <FieldNameInline label="Dropped: "></FieldNameInline>
              <span>The participant dropped the program.</span>
              <br />
              <FieldNameInline
                label="Check survey assignment dates: "
              ></FieldNameInline>
              <span
                >A "catch all" description indicating unknown status not meeting
                any of the other status listed here.</span
              >
              <br />
              <br />
            </div>
          </div>
        </div> -->

        <br />
        <br />
        <clip-loader
          :loading="setLoading"
          :color="setColor"
          :size="setSize"
        ></clip-loader>
        <v-card
          v-if="firstFetchUserData.length > 0 && showIndividualRecords == false"
          class="user-data-table-container"
        >
          <v-data-table
              :headers="userHeaders"
              :items="filteredUserData"
              items-per-page="50"
              return-object
              show-select
              v-model="selected"
              :loading="isLoading"
              density="compact"
              loading-text="Loading... Please wait"
              class="user-custom-data-table"
            >
            

            <!-- Filtering Code -->

          <template v-for="header in userHeaders" v-slot:[`header.${header.key}`]="{ column }" :key="header.key">
            <thead class="v-data-table-header">
              <tr>
                <th
                  v-bind:class="[column.sortable ? 'sortable' : '', pagination.sortBy == column.value ? 'active': '', pagination.descending ? 'desc':'asc']"
                  @click="column.sortable ? changeSort(column.value) : ''"
                >
                  <div class="title-container">
                    <span>{{ column.title }}</span>
                    <v-icon v-if="column.sortable" class="v-data-table-header__icon" small>
                      {{ pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                    </v-icon>
                  </div>
                  </th>
                  <th style="min-width:15px;">
                  <v-autocomplete
                    multiple
                    variant="underlined"
                    density="compact"
                    :items="userColumnValueList(column.value)"
                    v-model="filters[column.value]"
                    @click.stop="{/* Stop click event propagation */}"
                    class="column-header-filter"
                  >
                  <template v-slot:selection="{ index }">
                      <span v-if="index === 5">(+{{ filters[header.value].length - 5 }} others)</span>
                    </template>
                  </v-autocomplete>
                </th>
              </tr>  
            </thead>
          </template>

              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reload Table </v-btn>
              </template>
          </v-data-table>
        </v-card>

        <v-card
            class="survey-data-table-container"
            v-if="secondFetchUserData.length > 0 && showIndividualRecords == true">

            <v-select v-model="selectedHeaders" :items="survey_headers_data" 
            label="Select Columns to Focus Your View" 
            multiple 
            outlined 
            return-object
            v-if="secondFetchUserData.length > 0 && showIndividualRecords == true">
              <template v-slot:selection="{ item, index }">
                <v-chip variant="text" v-if="index < 6">
                  <span>{{ item.title }}</span>
                </v-chip>
                <span v-if="index === 6 && item.title !== 'Survey Invitee'" class="grey--text caption">
                  (+{{ selectedHeaders.length - 6 }} others)
                </span>
              </template>
         </v-select>
            <v-data-table
              :headers="displayedHeaders"
              :items="filteredSurveyAssignmentData"
              @update:options="handleOptionsUpdate"
              items-per-page="50"
              return-object
              v-model="selected"
              :loading="isLoading"
              density="compact"
              loading-text="Loading... Please wait"
              class="survey-custom-data-table"
              fixed-header
              height="100vh"
              :items-per-page-options="[50, 12, 100, 150, 200, -1]"
              theme="blue"
            >
            

            <!-- Filtering Code -->
          <template v-for="header in surveyHeaders" v-slot:[`header.${header.key}`]="{ column }" :key="header.key">
            <!-- text-align right the header if isNumeric = true -->
            <div v-if="column.isNumeric" style="display:flex; justify-content: flex-end;">
              <thead class="v-data-table-header">
                <tr>
                  <th
                  v-bind:class="[column.sortable ? 'sortable' : '', pagination.sortBy == column.value ? 'active': '', pagination.descending ? 'desc':'asc']"
                    @click="column.sortable ? changeSort(column.value) : ''"
                  >
                    <div class="title-container">
                      <span>{{ column.title }}</span>
                      <v-icon v-if="column.sortable" class="v-data-table-header__icon" small>
                        {{ pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                      </v-icon>
                    </div>
                  </th>
                  <th style="min-width:15px;">
                    <v-autocomplete
                      multiple
                      variant="underlined"
                      density="compact"
                      :items="surveyColumnValueList(column.value)"
                      v-model="filters[column.value]"
                      @click.stop="{}"
                      class="column-header-filter"
                    >
                      <template v-slot:selection="{ index }">
                        <span v-if="index === 5">(+{{ filters[header.value].length - 5 }} others)</span>
                      </template>
                    </v-autocomplete>
                  </th>
                </tr>  
              </thead>
            </div>
            <div v-else>
              <thead class="v-data-table-header">
                <tr>
                  <th
                  v-bind:class="[column.sortable ? 'sortable' : '', pagination.sortBy == column.value ? 'active': '', pagination.descending ? 'desc':'asc']"
                    @click="column.sortable ? changeSort(column.value) : ''"
                  >
                    <div class="title-container">
                      <span>{{ column.title }}</span>
                      <v-icon v-if="column.sortable" class="v-data-table-header__icon" small>
                        {{ pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                      </v-icon>
                    </div>
                  </th>
                  <th style="min-width:15px;">
                    <v-autocomplete
                      multiple
                      variant="underlined"
                      density="compact"
                      :items="surveyColumnValueList(column.value)"
                      v-model="filters[column.value]"
                      @click.stop="{}"
                      class="column-header-filter"
                    >
                      <template v-slot:selection="{ index }">
                        <span v-if="index === 5">(+{{ filters[header.value].length - 5 }} others)</span>
                      </template>
                    </v-autocomplete>
                  </th>
                </tr>  
              </thead>
            </div>
            
          </template>

          <!-- text-align right the data if isNumeric = true -->
          <!-- <template v-for="header in surveyHeaders" :key="header.key" v-slot:[`item.${header.key}`]="{ item }">
            <div v-if="header.isNumeric && header.key !== 'survey_invitee' && header.key !== 'participant_name'" style="display:flex; justify-content: flex-end;">
              <span>
                {{ item[header.key] }}
              </span>
            </div>
            <div v-else-if="header.key === 'survey_invitee'">
              <tr>
                <td>
                  <router-link class="participant-details-link" :to="{
                                  name: 'EditParticipantBox',
                                  query: { iccParticipantData: encrypt(JSON.stringify(item)),
                                            dataCheck: 'survey_invitee', 
                                            userData: JSON.stringify(userData),
                                            brandData: JSON.stringify(brandData),
                                        }
                              }" target="_blank">  
                    {{ item.survey_invitee }}
                  </router-link>
                </td>
              </tr>
            </div>
            <div v-else-if="header.key === 'participant_name'">
              <tr>
                <td>
                  <router-link class="participant-details-link" :to="{
                                  name: 'EditParticipantBox',
                                  query: { iccParticipantData: encrypt(JSON.stringify(item)),
                                            dataCheck: 'participant', 
                                            userData: JSON.stringify(userData),
                                            brandData: JSON.stringify(brandData),
                                        }
                              }" target="_blank">  
                    {{ item.participant_name }}
                  </router-link>
                </td>
              </tr>
            </div>
            <div v-else>
              <span>
                {{ item[header.key] }}
              </span>
            </div>
          </template> -->

          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reload Table </v-btn>
          </template>
            </v-data-table>
        </v-card>
        <br />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AdminDashboardClientAdminViewer",
  component: [
    DescriptionInline,
    DropDownInfo,
    DropDownInfoNumbered,
    HeaderReport,
    FieldNameInline,
    ButtonSubmit,
    Multiselect,
    ClipLoader,
    Header,
  ],
  props: ["buttonLabel", "userData", "brandData"],

  data: () => ({
    currentItems: [],
    isLoading: true,
    pagination: {
        sortBy: null,
        descending: false,
        page: 1,
        itemsPerPage: 5
      },
    filters: {},
    activeFilters: {},
    disableSearchButton: false,
    setColor: "#ff6a00",
    setSize: "50px",
    showIndividualRecords: true,
    toggleGospiPicker: true,
    toggle2: false,
    toggle: false,
    setLoading: false,
    surveyType: "",
    statisticsData: [],
    participantsRegisteredPercentage: 0,
    participantsSubmittedPercentage: 0,
    participantsEligiblePercentage: 0,
    totalNomineesPercentage: 0,
    totalNomineesSubmittedPercentage: 0,
    iterationLogTableRows: null,
    rowLimit: 5,
    surveyAssignmentList: [],
    table_headers: [
      "NAME",
      "program_name",
      "iteration_name",
      "stream_name",
      "group_name",
    ],
    table2_headers: [
      "full_name",
      "logged_in",
      "survey_template_name",
      "tag_names",
      "launch_date",
      "survey_reminder_date",
      "initial_deadline_date",
      "final_deadline_date",
      "coach_report_start_date",
      "coach_report_end_date",
      "participant_report_start_date",
      "stream_name",
      "group_name",
      "coach_email",
      "coach_access_granted",
      "coach_group_access_granted",
      "STATUS",
      "undropped",
      "nominations_submitted",
    ],
    user_headers_data: [
        { title: "Name", 
          key: "NAME", 
          width: "2%" 
        },
        { title: "Program", 
          key: "program_name", 
          width: "2%" 
        },
        { title: "Iteration", key: "iteration_name", width: "2%" },
        { title: "Stream", key: "stream_name", width: "2%" },
        { title: "Group", key: "group_name", width: "2%" },
    ],
    survey_headers_data: [
        { title: "Participant", 
          key: "participant_name",
          isNumeric: false,
        },
        { 
          title: "Last Logged in", 
          key: "last_login_date", 
          isNumeric: true,
        },
        { 
          title: "Survey Template", 
          key: "survey_template_name", 
          isNumeric: false,
        },
        { 
          title: "Survey Launch", 
          key: "launch_date",  
          isNumeric: true,
        },
        { 
          title: "Custom Reminder Date", 
          key: "survey_reminder_date", 
          isNumeric: true,
        },
        { 
          title: "Survey Initial Deadline", 
          key: "initial_deadline_date", 
          isNumeric: true,
        },
        { 
          title: "Survey Final Deadline", 
          key: "final_deadline_date", 
          isNumeric: true,
        },
        { 
          title: "Survey Progress", 
          key: "survey_progress", 
          isNumeric: false,
        },
        { 
          title: "Report Criteria Met", 
          key: "participant_report_criteria_met", 
          isNumeric: false,
        },
        { 
          title: "Self received", 
          key: "self_recd", 
          isNumeric: false,
        },
        { 
          title: "Nominations", 
          key: "nominations_submitted", 
          isNumeric: true,
        },
        { 
          title: "PS Nominations", 
          key: "psup_submitted", 
          isNumeric: true,
        },
        { 
          title: "Stream", 
          key: "stream_name", 
          isNumeric: false,
        },
        { 
          title: "Group", 
          key: "group_name",
          isNumeric: false,
        },
        { 
          title: "Primary Coach", 
          key: "coach_email", 
        },
        { 
          title: "Secondary Coach", 
          key: "secondary_coach_email", 
          isNumeric: false,
        },
        { 
          title: "Coach Permission Given", 
          key: "coach_access_granted", 
          isNumeric: false,
        },
        { 
          title: "HR Permission Given", 
          key: "hr_access_granted", 
          isNumeric: false,
        },
        { 
          title: "Coach Group Permission Given", 
          key: "coach_group_access_granted", 
          isNumeric: false,
        },
        { 
          title: "Coach report release", 
          key: "coach_report_start_date",
          isNumeric: true,
        },
        { 
          title: "Coach report end", 
          key: "coach_report_end_date", 
          isNumeric: true,
        },
        { 
          title: "Participant report release", 
          key: "participant_report_start_date", 
          isNumeric: true,
        },
        { 
          title: "Report status", 
          key: "report_status", 
          isNumeric: false,
        },
    ],
    selectedHeaders: [],
    displayedHeaders: [],
    columnHideShowHeader: [],
    show_nomination_modal: false,
    show_nominee_modal: false,
    surveyAssignmentId: "",
    nominee_ind_id: "",
    nominee_survey_assignment_id: "",
    sortDirection: 1,
    sortBy: "",
    allSelected: false,
    org_id: "Loading Data...",
    coach_email_inp: null,
    secondary_coach_email_inp: null,
    suborg_inp: null,
    program_inp: null,
    program_id: null,
    iteration_id: null,
    iteration_inp: null,
    stream_id: null,
    stream_inp: null,
    group_inp: null,
    template_id: null,
    actions: null,
    tags: null,
    surveyTemplate: null,
    selected: [],
    firstFetchUserData: [],
    secondFetchUserData: [],
    userOrg: [],
    userSubOrg: [],
    userPrograms: [],
    coachUsers: [],
    userIterations: [],
    userStreams: [],
    userGroups: [],
    userSurveyTemplate: [],
    batchActions: [],
    batchActions2: [],
    userTags: [],
    filteredData: [],
    newNameSuborg: [],
    nomineeSurveyTemplateId: 0,
    dates: {
      launch: "",
      reminder: "",
      initial_deadline: "",
      coach_report_start: "",
      coach_report_end: "",
      deadline_final: "",
      participant_report_start: "",
      modified_by: "",
    },
    survey: {
      org_id: "",
      suborg: {
        suborg_name: 'Select Sub-Org',
      },
      program: {
        program_id: 0,
        program_name: 'Select Program',
      },
      iteration: {
        iteration_id: 0,
        iteration_name: 'Select Iteration',
      },
      stream: {
        stream_id: 0,
        stream_name: 'Select Stream',
      },
      suborg_id: null,
      survey_template_id: null,
      survey_template_association_id: "",
      is_test: "",
      for_sample_report: "",
      program_id: null,
      iteration_id: null,
      stream_id: null,
      group_id: null,
      action_program_id: null,
      action_iteration_id: null,
      action_stream_id: null,
      action_tag: null,
      // action_group_id: null,
      start: "",
      launch: "",
      reminder: "",
      deadline_initial: "",
      deadline_final: "",
      coach_report_start: "",
      coach_report_end: "",
      participant_report_start: "",
      recommended_number_of_nominations: "",
      max_number_of_nominations: "",
      report_eligible_number_of_respondents: "",
      created_by: 1,
      modified_by: 1,
    },
    selected_is_nomination: 0,
  }),
  async mounted() {
    const keys = ['suborg_id', 'program_id', 'iteration_id', 'stream_id'];
    keys.forEach((key) => {
      const value = this.getFromCookie(key);

      if (value == undefined || value == null) {
        this.storeInCookie(key, 0);
      }
    });
    // console.log(this.brandData);
    this.selectedHeaders = this.survey_headers_data;

    await this.renderData();

    this.survey.org_id = this.userData.org_id;

    await api
      .get(`/individuals/get-coach-by-org/${this.survey.org_id}`)
      .then((result) => {
        // console.log(result.data)
        this.coachUsers = result.data;
      });

    await api.get("individuals/" + this.userData.ind_id).then((result) => {
      this.org_id = result.data.org_name;
    });

    await api.get("batch-actions/").then((result) => {
      this.batchActions = result.data;
      this.batchActions2 = result.data;
    });

    await api.get("tags/").then((result) => {
      this.userTags = result.data;
    });

    await api.get("organizations").then((result) => {
      result.data.map((data) => {
        this.survey.max_number_of_nominations =
          data.d_max_number_of_nominations;
        this.survey.recommended_number_of_nominations =
          data.d_recommended_number_of_nominations;
        this.survey.report_eligible_number_of_respondents =
          data.d_report_eligible_number_of_respondents;
      });
      this.userOrg = result.data;
    });

    await api.get("survey-templates-survey_template_name").then((result) => {
      this.userSurveyTemplate = result.data;
    });

    this.loadStoredValues();

    const selectItem = this.userSubOrg.find(suborg => suborg.suborg_id === 0)
    if (!selectItem) {
      // Create a 'Select' item with suborg_id of 0
      const newSelectItem = { suborg_id: 0, suborg_name: 'Select Sub-Org', org_id: this.userData.org_id };
      // Add the 'Select' item to the beginning of the array
      this.userSubOrg.unshift(newSelectItem);
    }

    if(this.survey.suborg) {
      this.updateCookieSuborg()
    }

  },
  methods: {
    selectAll() {
      if (this.allSelected) {
        const selected = this.secondFetchUserData.map(
          (u) => u
        );
        this.selected = selected;
      } else {
        this.selected = [];
      }
    },

    encrypt(src) {
      let passphrase = "iccData"
      return CryptoJS.AES.encrypt(src, passphrase).toString();
    },

    handleOptionsUpdate() {
      console.log("Excel Data:", this.filteredData2);
    },
    async downloadExcel() {
      try {
        // Create a new array with objects having 'title' as key
        const dataForExcel = this.filteredData2.map(item => {
          const newData = {};
          this.survey_headers_data.forEach(header => {
            newData[header.title] = item[header.key];
          });
          return newData;
        });

        const worksheet = XLSX.utils.json_to_sheet(dataForExcel);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Save the workbook
        XLSX.writeFile(workbook, 'data.xlsx');
        Swal.fire({
          text: 'Excel file downloaded successfully.',
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false
        });
      } catch (error) {
        Swal.fire({
          text: 'Error during Excel download: ' + error.message,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false
        });
        console.error('Error during Excel download:', error);
      }
    },
    updateCookieSuborg() {
      if(this.filteredSubOrg.length === 2){
          this.survey.suborg = this.filteredSubOrg[1]
          this.updateCookie()
        }
    },
    updateCookie() {
      if(this.filteredProgram.length === 2){
        this.survey.program = this.filteredProgram[1]
        if(this.filteredIteration.length === 2){
          this.survey.iteration = this.filteredIteration[1]
        } else {
          this.survey.iteration = {}
        }
      } else{
        this.survey.program = {}
        this.survey.iteration = {}
        this.survey.stream = {}
      }
    },
    storeInCookie(key, value) {
      VueCookies.set(key, value, '1d'); // Store for 7 days
    },
    getFromCookie(key) {
      return VueCookies.get(key);
    },
    clearCookie(key) {
      VueCookies.remove(key);
    },
    clearAllCookies() {
      VueCookies.keys().forEach(key => {
        VueCookies.remove(key);
      });
    },
    loadStoredValues() {
      this.survey.suborg.suborg_id = this.getFromCookie('suborg_id') || null;
      this.survey.program.program_id = this.getFromCookie('program_id') || null;
      this.survey.iteration.iteration_id = this.getFromCookie('iteration_id') || null;
      this.survey.stream.stream_id = this.getFromCookie('stream_id') || null;

      //suborg
      const storedSuborgId = this.getFromCookie('suborg_id') || null;
      const storedSuborgIdConverted = storedSuborgId ? parseInt(storedSuborgId, 10) : null;
      const matchedSuborg = this.filteredSubOrg.find(suborg => {
        return suborg.suborg_id === storedSuborgIdConverted;
      });
      this.survey.suborg = matchedSuborg || { id: null, name: null };

      //program
      const storedProgramId = this.getFromCookie('program_id') || null;
      const storedProgramIdConverted = storedProgramId ? parseInt(storedProgramId, 10) : null;
      const matchedProgram = this.filteredProgram.find(program => {
        return program.program_id === storedProgramIdConverted;
      });
      this.survey.program = matchedProgram || { id: null, name: null };

      //Iteration
      const storedIterationId = this.getFromCookie('iteration_id') || null;
      const storedIterationIdConverted = storedIterationId ? parseInt(storedIterationId, 10) : null;
      const matchedIteration = this.filteredIteration.find(iteration => {
        return iteration.iteration_id === storedIterationIdConverted;
      });
      this.survey.iteration = matchedIteration || { id: null, name: null };

      //Stream
      const storedStreamId = this.getFromCookie('stream_id') || null;
      const storedStreamIdConverted = storedStreamId ? parseInt(storedStreamId, 10) : null;
      const matchedStream = this.filteredStream.find(stream => {
        return stream.stream_id === storedStreamIdConverted;
      });
      this.survey.stream = matchedStream || { id: null, name: null };

    },
    async sort(head) {
      const sorted = `Sorted ${
        head == "NAME"
          ? "Name"
          : head == "program_name"
          ? "Program"
          : head == "iteration_name"
          ? "Iteration"
          : head == "full_name"
          ? "Full Name"
          : head == "logged_in"
          ? "Logged In"
          : head == "survey_template_name"
          ? "Template"
          : head == "tag_names"
          ? "Tags"
          : head == "launch_date"
          ? "Launch"
          : head == "survey_reminder_date"
          ? "Deadline reminder"
          : head == "initial_deadline_date"
          ? "Deadline"
          : head == "final_deadline_date"
          ? "Final deadline"
          : head == "coach_report_start_date"
          ? "Coach report"
          : head == "participant_report_start_date"
          ? "User report"
          : head == "stream_name"
          ? "Stream"
          : head == "group_name"
          ? "Group"
          : head == "coach_email"
          ? "Coach"
          : head == "coach_access_granted"
          ? "Coach access granted"
          : head == "coach_group_access_granted"
          ? "Coaching group access granted"
          : head == "STATUS"
          ? "Status"
          : head == "nominations_submitted"
          ? "Nominations submitted"
          : head
      } column successfully.`;
      this.sortBy = head;
      this.sortDirection *= -1;

      if (head == "" && head == undefined) {
        this.searchResult();
      } else {
        this.searchResult(sorted);
      }
    },
    surveyInitFilters() {
      // Initialize filters based on the items in the data
      for (const col of this.survey_headers_data) {
        this.filters[col.key] = this.secondFetchUserData.map(item => item[col.key]).filter(
          (value, index, self) => self.indexOf(value) === index
        );
      }
      // TODO restore previous activeFilters before add/remove item
      this.activeFilters = { ...this.filters };
    },

    userInitFilters() {
      // Initialize filters based on the items in the data
      for (const col of this.user_headers_data) {
        this.filters[col.key] = this.firstFetchUserData.map(item => item[col.key]).filter(
          (value, index, self) => self.indexOf(value) === index
        );
      }
      // TODO restore previous activeFilters before add/remove item
      this.activeFilters = { ...this.filters };
    },

    surveyColumnValueList(val) {
        const uniqueValues = new Set();
        
        this.secondFetchUserData.forEach((d) => {
          const value = d[val];
          
          // Check if the value is not empty, null, and not already added to the Set
          if (value !== '' && value !== null && !uniqueValues.has(value)) {
            uniqueValues.add(value);
          }
        });

        // Convert the Set back to an array and return it
        return Array.from(uniqueValues);
    },

    userColumnValueList(val) {
        const uniqueValues = new Set();
        
        this.firstFetchUserData.forEach((d) => {
          const value = d[val];
          
          // Check if the value is not empty, null, and not already added to the Set
          if (value !== '' && value !== null && !uniqueValues.has(value)) {
            uniqueValues.add(value);
          }
        });

        // Convert the Set back to an array and return it
        return Array.from(uniqueValues);
    },

    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

    showNominationModal(data) {
      this.show_nomination_modal = true;
      this.surveyAssignmentId = data;
    },

    showNomineeModal(ind_id, survey_assignment_id) {
      this.show_nominee_modal = true;
      this.nominee_ind_id = ind_id;
      this.nominee_survey_assignment_id = survey_assignment_id;
    },
    // sortMethods(head, direction) {
    //   return direction === 1wSurveytype
    //     ? (a, b) => (b[head] > a[head] ? -1 : a[head] > b[head] ? 1 : 0)
    //     : (a, b) => (a[head] > b[head] ? -1 : b[head] > a[head] ? 1 : 0);
    // },

    dynamicSort(property, direction) {
      if (property[0] === "-") {
        property = property.substr(1);
      }
      return function (a, b) {
        var result =
          a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
        // console.log(result)
        if (a[property] === "" || a[property] === null) return 1;
        if (b[property] === "" || b[property] === null) return -1;
        if (a[property] === b[property]) return 0;
        return result * direction;
        // console.log(result * direction);
        // return result * direction;
      };
    },

    async showSurveyType() {
      this.disableSearchButton = true;
      await api
        .get(`/survey-templates/survey-type/${this.template_id}`)
        .then((result) => {
            console.log(result.data);
            this.surveyType = result.data.survey_type;
            if (result.data.nominee_survey_template_id != null) {
              this.nomineeSurveyTemplateId = result.data.nominee_survey_template_id;
            }
        });
      setTimeout(this.enableSearchButton, 2000);
    },

    enableSearchButton() {
      this.disableSearchButton = false;
    },

    async renderData() {
      await api.get("sub-organizations/").then((result) => {
        this.userSubOrg = result.data;
      });

      await api.get("programs").then((result) => {
        this.userPrograms = result.data;
      });

      await api.get("iterations").then((result) => {
        this.userIterations = result.data;
      });

      await api.get("streams").then((result) => {
        this.userStreams = result.data;
      });

      await api.get("groups").then((result) => {
        this.userGroups = result.data;
      });
    },

    incrementIndex(index) {
      return index + 1;
    },

    async swalValidation() {
      if (this.survey.iteration.iteration_id == null && this.actions == 19) {
        try {
          Swal.fire({
            text: `You didn't select an iteration at the top. Please select one.`,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.firstFetchUserData = [];
              this.toggle = false;
              this.allSelected = false;
              this.actions = null;
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else if (this.survey.launch == "" && this.survey.launch == null) {
        try {
          Swal.fire({
            text: `Lauch date is required. Please input a date.`,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.survey.launch = "";
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else if (
        this.survey.deadline_final == "" &&
        this.survey.deadline_final == null
      ) {
        try {
          Swal.fire({
            text: `Final deadline date is required. Please input a date.`,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.survey.deadline_final = "";
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          this.firstHandleSave();
        } catch (error) {
          console.error(error);
        }
      }
    },

    firstHandleSave: async function () {
      //Add survey
      if (this.actions == 19) {
        for (const data of this.userSurveyTemplate) {
          if (data.survey_template_id === this.survey.survey_template_id) {
            this.survey.survey_template_association_id =
              data.survey_template_association_id;
          }
        }
        if (!this.survey.deadline_initial || !this.survey.deadline_final) {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#F47820",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Initial deadline and final deadline are required fields"
          );
        } else {
          var pass = this.validateDates(
            this.survey.deadline_initial,
            this.survey.deadline_final
          );
          if (!pass) {
            return flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color
                ? this.brandData.flash_text_color
                : "#ffffff",
              "Please make sure the deadline final date is after the deadline initial date"
            );
          }
          if (this.survey.coach_report_start && this.survey.coach_report_end) {
            pass = this.validateDates(
              this.survey.coach_report_start,
              this.survey.coach_report_end
            );
            if (!pass) {
              return flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                "Please make sure the coach report end date is after the coach report start date"
              );
            }
          }
        }
        this.actions = null;
        this.toggle = false;

        for (var i = 0; i < this.selected.length; i++) {
          await api
            .post("survey-assignments", {
              ...this.survey,
              logged_in_user: this.userData.ind_id,
              ind_id: this.selected[i].ind_id,
              recipient_email: this.selected[i].email,
            })
            .then(() => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                this.selected.length > 1
                  ? "Adding survey to selected users... Please wait for this message to be gone."
                  : "Successfully added survey to the selected user."
              );
              try {
                const res = api.post(`/create-user-history-log`, {
                  ind_id: this.userData.ind_id,
                  email: this.userData.email,
                  screen: 'ICC',
                  action: `Add survey batch action`,
                  action_description: `Assigned survey_template_id ${this.survey.survey_template_id} to email ${this.selected[i].email} with ind_id ${this.selected[i].ind_id}`,
                })
                if(res.status === 200) {
                  console.log('user-history-log API return :', res)
                }
              } catch (error) {
                console.log('Error is :', error)
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        //Add additional iteration
      } else if (this.actions == 21) {
        this.actions = null;
        this.toggle = false;
        if (
          this.survey.action_program_id != null &&
          this.survey.suborg.suborg_id != null
        ) {
          for (var j = 0; j < this.selected.length; j++) {
            await api
              .put(
                `individuals/ind-group-program-iteration/${this.selected[j].ind_id}`,
                {
                  ...this.survey,
                }
              )
              .then(() => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  "Modifying selected user(s)... Please wait for this message to be gone."
                );
                try {
                const res = api.post(`/create-user-history-log`, {
                  ind_id: this.userData.ind_id,
                  email: this.userData.email,
                  screen: 'ICC',
                  action: `Update program/iteration batch action`,
                  action_description: `Update ind_id ${this.selected[j].ind_id} from program_id ${this.survey.program.program_id} and iteration_id ${this.survey.iteration.iteration_id} to new program_id ${this.survey.action_program_id} and new iteration_id ${this.survey.action_iteration_id}`,
                })
                if(res.status === 200) {
                  console.log('user-history-log API return :', res)
                }
              } catch (error) {
                console.log('Error is :', error)
              }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          try {
            Swal.fire({
              text: `You didn't select a sub-organization at the top. Please select one.`,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                this.firstFetchUserData = [];
                this.toggle = false;
                this.allSelected = false;
                this.actions = null;
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
        //Assign group
      } else if (this.actions == 22) {
        this.actions = null;
        this.toggle = false;
        if (
          this.survey.action_stream_id != null &&
          this.survey.suborg.suborg_id != null &&
          this.survey.program.program_id !== null &&
          this.survey.iteration.iteration_id != null
        ) {
          for (var k = 0; k < this.selected.length; k++) {
            await api
              .put(
                `individuals/ind-group-stream-group/${this.selected[k].ind_id}`,
                {
                  ...this.survey,
                }
              )
              .then(() => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  "Modifying selected user(s)... Please wait for this message to be gone."
                );
                try {
                  const res = api.post(`/create-user-history-log`, {
                    ind_id: this.userData.ind_id,
                    email: this.userData.email,
                    screen: 'ICC',
                    action: `Assign group batch action`,
                    action_description: `Assign group to ind_id ${this.selected[k].ind_id} stream_id = ${this.survey.action_stream_id} and group_id ${this.survey.group_id}`,
                  })
                  if(res.status === 200) {
                    console.log('user-history-log API return :', res)
                  }
                } catch (error) {
                  console.log('Error is :', error)
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }
      this.survey.action_program_id = null;
      this.survey.action_iteration_id = null;
      this.survey.action_stream_id = null;
      this.survey.group_id = null;
      this.allSelected = false;
      this.selected = [];
      this.searchResult();
    },

    secondHandleSave: async function () {
      //Add tag
      if (this.actions == 20) {
        this.actions = null;
        this.toggle = false;

        for (var i = 0; this.selected.length > i; i++) {
          await api
            .post("survey_assignment_tags/", {
              survey_assignment_id: this.selected[i],
              tag_id: this.tags,
              org_id: this.survey.org_id,
            })
            .then(() => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                this.selected.length > 1
                  ? "Adding tags to selected users... Please wait for this message to be gone."
                  : "Successfully added tag to the selected user."
              );
              try {
                  const res = api.post(`/create-user-history-log`, {
                    ind_id: this.userData.ind_id,
                    email: this.userData.email,
                    screen: 'ICC',
                    action: `Add tag batch action`,
                    action_description: `Add tag_id ${this.tags} to ind_id ${this.selected[i].ind_id}`,
                  })
                  if(res.status === 200) {
                    console.log('user-history-log API return :', res)
                  }
                } catch (error) {
                  console.log('Error is :', error)
                }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        this.allSelected = false;
        this.selected = [];
        this.tags = null;
        this.searchResult();
        //Change final deadline date
      } else if (this.actions == 7) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/dates/${newValue}`, {
            deadline_final: this.dates.deadline_final,
            modified_at: "",
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change final deadline date batch action`,
                action_description: `Change final deadline date for survey_assignment_id ${newValue} to final deadline date ${this.dates.deadline_final}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            api.get(`survey-assignment/earliest-final-deadline-date/${this.survey.iteration.iteration_id}`)
            .then((result)=>{
                api.put(`iterations/final-deadline-date-update/${result.data.iteration_id}`, {
                  final_deadline_date: result.data.earliest_final_deadline,
                  modified_by: this.userData.ind_id
                }).then(()=>{
                  flashMessage(
                    this.$flashMessage,
                    this.brandData.accent_color1
                      ? this.brandData.accent_color1
                      : "#F47820",
                    this.brandData.flash_text_color,
                    this.selected.length > 1
                      ? "Updating final deadline date to selected users... Please wait for this message to be gone."
                      : "Successfully update final deadline date of the selected user."
                  );
                })
            })
            this.dates.deadline_final = "";
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
        //Change launch date
      } else if (this.actions == 8) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/dates/${newValue}`, {
            launch: this.dates.launch,
            modified_at: "",
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Updating launch date to selected users... Please wait for this message to be gone."
                : "Successfully update launch date of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change launch date batch action`,
                action_description: `Change launch date for survey_assignment_id ${newValue} to launch date ${this.dates.launch}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.dates.launch = "";
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
        //Change survey reminder date
      } else if (this.actions == 9) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/dates/${newValue}`, {
            reminder: this.dates.reminder,
            modified_at: "",
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Updating survey reminder date to selected users... Please wait for this message to be gone."
                : "Successfully update survey reminder date of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change survey reminder date batch action`,
                action_description: `Change survey reminder date for survey_assignment_id ${newValue} to Survey reminder date ${this.dates.reminder}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.dates.reminder = "";
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
        //Change coach report start date
      } else if (this.actions == 10) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/dates/${newValue}`, {
            coach_report_start: this.dates.coach_report_start,
            modified_at: "",
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Updating coach report start date to selected users... Please wait for this message to be gone."
                : "Successfully update coach report start date of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change coach report start date batch action`,
                action_description: `Change coach report start date for survey_assignment_id ${newValue} to Coach report start date ${this.dates.coach_report_start}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.dates.coach_report_start = "";
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
        //Change coach report end date
      } else if (this.actions == 11) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/dates/${newValue}`, {
            coach_report_end: this.dates.coach_report_end,
            modified_at: "",
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Updating coach report end date to selected users... Please wait for this message to be gone."
                : "Successfully update coach report end date of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change coach report end date batch action`,
                action_description: `Change coach report end date for survey_assignment_id ${newValue} to Coach report end date ${this.dates.coach_report_end}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.dates.coach_report_end = "";
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
        //Change participant report start date
      } else if (this.actions == 12) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/dates/${newValue}`, {
            participant_report_start: this.dates.participant_report_start,
            modified_at: "",
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Updating participant report start date to selected users... Please wait for this message to be gone."
                : "Successfully update participant report start date of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change participant report start date batch action`,
                action_description: `Change participant report start date for survey_assignment_id ${newValue} to Participant report start date ${this.dates.participant_report_start}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.dates.participant_report_start = "";
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
        //Change initial deadline date
      } else if (this.actions == 13) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/dates/${newValue}`, {
            initial_deadline: this.dates.initial_deadline,
            modified_at: "",
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Updating initial deadline date to selected users... Please wait for this message to be gone."
                : "Successfully update initial deadline date of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change initial deadline date batch action`,
                action_description: `Change initial deadline date for survey_assignment_id ${newValue} to Initial deadline date ${this.dates.initial_deadline}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.dates.initial_deadline = "";
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // change group name
      else if (this.actions == 5) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/group-name/${newValue}`, {
            group_id: this.group_inp,
            coach_id: null,
            secondary_coach_id: null,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `change group name batch action`,
                action_description: `change group for survey_assignment_id ${newValue} to group_id ${this.group_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            api
              .put(`/survey-assignment/change-nominee-group/${newValue}`, {
                group_id: this.group_inp,
                coach_id: null,
                secondary_coach_id: null,
                modified_by: this.userData.ind_id,
              })
              .then(() => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color,
                  this.selected.length > 1
                    ? "Updating group to selected users... Please wait for this message to be gone."
                    : "Successfully update group of the selected user."
                );
                this.group_inp = null;
                this.allSelected = false;
                this.selected = [];
                this.searchResult();
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // change stream name
      else if (this.actions == 4) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/stream-name/${newValue}`, {
            stream_id: this.stream_inp,
            coach_id: null,
            secondary_coach_id: null,
            group_id: 0,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `change stream name batch action`,
                action_description: `change stream name for survey_assignment_id ${newValue} to stream_id ${this.stream_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            api
              .put(`/survey-assignment/change-nominee-stream/${newValue}`, {
                stream_id: this.stream_inp,
                coach_id: null,
                secondary_coach_id: null,
                group_id: 0,
                modified_by: this.userData.ind_id,
              })
              .then(() => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color,
                  this.selected.length > 1
                    ? "Updating stream to selected users... Please wait for this message to be gone."
                    : "Successfully update stream of the selected user."
                );
                this.stream_inp = null;
                this.allSelected = false;
                this.selected = [];
                this.searchResult();
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // change iteration name
      else if (this.actions == 3) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/iteration-name/${newValue}`, {
            iteration_id: this.iteration_inp,
            coach_id: null,
            secondary_coach_id: null,
            stream_id: 0,
            group_id: 0,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `change iteration name batch action`,
                action_description: `change iteration for survey_assignment_id ${newValue} to iteration_id ${this.iteration_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            api
              .put(`/survey-assignment/change-nominee-iteration/${newValue}`, {
                iteration_id: this.iteration_inp,
                coach_id: null,
                secondary_coach_id: null,
                stream_id: 0,
                group_id: 0,
                modified_by: this.userData.ind_id,
              })
              .then(() => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color,
                  this.selected.length > 1
                    ? "Updating iteration to selected users... Please wait for this message to be gone."
                    : "Successfully update iteration of the selected user."
                );
                this.iteration_inp = null;
                this.allSelected = false;
                this.selected = [];
                this.searchResult();
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // change program name
      else if (this.actions == 2) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/program-name/${newValue}`, {
            program_id: this.program_inp,
            coach_id: null,
            secondary_coach_id: null,
            iteration_id: 0,
            stream_id: 0,
            group_id: 0,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `change program name batch action`,
                action_description: `change program for survey_assignment_id ${newValue} to program_id ${this.program_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            api
              .put(`/survey-assignment/change-nominee-program/${newValue}`, {
                program_id: this.program_inp,
                coach_id: null,
                secondary_coach_id: null,
                iteration_id: 0,
                stream_id: 0,
                group_id: 0,
                modified_by: this.userData.ind_id,
              })
              .then(() => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color,
                  this.selected.length > 1
                    ? "Updating program to selected users... Please wait for this message to be gone."
                    : "Successfully update program of the selected user."
                );
                this.iteration_inp = null;
                this.allSelected = false;
                this.selected = [];
                this.searchResult();
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // change suborg name
      else if (this.actions == 1) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/change-suborg/${newValue}`, {
            suborg_id: this.suborg_inp,
            coach_id: null,
            secondary_coach_id: null,
            program_id: 0,
            iteration_id: 0,
            stream_id: 0,
            group_id: 0,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `change suborg name batch action`,
                action_description: `change suborg for survey_assignment_id ${newValue} to suborg_id ${this.suborg_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            api
              .put(`/survey-assignment/change-nominee-suborg/${newValue}`, {
                suborg_id: this.suborg_inp,
                coach_id: null,
                secondary_coach_id: null,
                program_id: 0,
                iteration_id: 0,
                stream_id: 0,
                group_id: 0,
                modified_by: this.userData.ind_id,
              })
              .then(() => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color,
                  this.selected.length > 1
                    ? "Updating suborg to selected users... Please wait for this message to be gone."
                    : "Successfully update suborg of the selected user."
                );
                this.suborg_inp = null;
                this.allSelected = false;
                this.selected = [];
                this.searchResult();
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // drop
      else if (this.actions == 14) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/drop-undrop/${newValue}`, {
            dropped_status: 1,
            undropped_date: null,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `drop batch action`,
                action_description: `drop survey_assignment_id ${newValue}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Updating drop selected users... Please wait for this message to be gone."
                : "Successfully drop selected user."
            );
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      //undrop
      else if (this.actions == 15) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/drop-undrop/${newValue}`, {
            dropped_status: 0,
            dropped_date: null,
            undropped: 1,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `undrop batch action`,
                action_description: `undrop survey_assignment_id ${newValue}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Updating undrop selected users... Please wait for this message to be gone."
                : "Successfully undrop the selected user."
            );
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      //Un-Submit a Survey
      else if (this.actions == 23) {
          this.actions = null;
          this.toggle = false;
          let totalProcessed = 0; // Counter to track the number of processed surveys
          let totalNotSubmitted = 0; // Counter to track the number of surveys not submitted

          for (var j = 0; j < this.selected.length; j++) {
            await api
              .get(`/survey-assignments/${this.selected[j].survey_assignment_id}`) // jhun quick fix for the un-submit not working, need to review.
              .then((result) => {
                let selectedSubmittedStatus = result.data.submitted_status;
                let selectedFullname = result.data.user_full_name;
                // console.log(selectedSubmittedStatus);
                // console.log(selectedFullname);
                totalProcessed++; // Increment the processed counter

                if (selectedSubmittedStatus != 1) {
                  alert(`Individual ${selectedFullname} did not submit the survey yet.`);
                  totalNotSubmitted++; // Increment the not submitted counter
                } else if (selectedSubmittedStatus == 1) {
                  api.put(
                    `/survey-assignment/un-submit-survey/${this.selected[j].survey_assignment_id}`,
                    {
                      submitted_status: 0,
                      modified_by: this.userData.ind_id,
                    }
                  ).then(() => {
                    try {
                      const res = api.post(`/create-user-history-log`, {
                        ind_id: this.userData.ind_id,
                        email: this.userData.email,
                        screen: 'ICC',
                        action: `Un-Submit a Survey batch action`,
                        action_description: `Un-Submit Survey for survey_assignment_id ${this.selected[j].survey_assignment_id}`,
                      });
                      if(res.status === 200) {
                        console.log('user-history-log API return :', res);
                      }
                    } catch (error) {
                      console.log('Error is :', error);
                    }
                  }).catch((error) => {
                    console.log('Error in un-submitting survey:', error);
                  });
                }
              }).catch((error) => {
                console.log('Error in fetching survey assignment:', error);
              });
          }

          // Display the alert with the summary of the unsubmission process
          alert(`Un-Submit Survey(s) Completed! \nTotal Processed: ${totalProcessed} \nTotal Un-Submitted: ${totalNotSubmitted}`);
      }
      //remove tag
      else if (this.actions == 24) {
        this.actions = null;
        this.toggle = false;
        const survey_assignment_id = this.selected.join(",");
        const tag_id = this.survey.action_tag.join(",");

        api
          .delete(
            `del-survey_assignment_tags/survey-assignment/${survey_assignment_id}/tag/${tag_id}`
          )
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Deleting tag(s) of selected users... Please wait for this message to be gone."
                : "Successfully delete tag(s) of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Remove tag batch action`,
                action_description: `Remove tag_id ${tag_id} to ind_id ${survey_assignment_id}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      //run iteration
      else if (this.actions == 25) {
        this.toggle = false;

        let surveyType;

        if (this.surveyType == 1) {
          surveyType = "big5";
        } else if (this.surveyType == 2) {
          surveyType = "360";
        } else if (this.surveyType == 4) {
          surveyType = "qsort";
        }

      Swal.fire({
        text: "Running the Iteration will recalculate the cohort scores and regenerate PDF's for every participant. Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: this.brandData.accent_color1,
        confirmButtonText: "Yes",
        cancelButtonColor: this.brandData.accent_color1,
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
                api.post(`/force-cohort-processing/${surveyType}`, {
                  iteration_id: this.survey.iteration.iteration_id,
                }).then((res) => {
                  if (res.status >= 200 || res.status <= 299) {
                    api.post(`/iteration-log`, {
                      iteration_id: this.survey.iteration.iteration_id,
                      action_made: this.actions,
                      action_by: this.userData.email,
                      action_by_id: this.userData.ind_id,
                      status: "Success",
                      org_id: this.survey.org_id,
                      suborg_id: this.survey.suborg.suborg_id,
                      program_id: this.survey.program.program_id,
                      created_by: this.userData.ind_id,
                      modified_by: this.userData.ind_id,
                    });
                  } else {
                    api.post(`/iteration-log`, {
                      iteration_id: this.survey.iteration.iteration_id,
                      action_made: this.actions,
                      action_by: this.userData.email,
                      action_by_id: this.userData.ind_id,
                      status: "Failed",
                      org_id: this.survey.org_id,
                      suborg_id: this.survey.suborg.suborg_id,
                      program_id: this.survey.program.program_id,
                      created_by: this.userData.ind_id,
                      modified_by: this.userData.ind_id,
                    });
                  }

                  flashMessage(
                    this.$flashMessage,
                    this.brandData.accent_color1,
                    this.brandData.flash_text_color,
                    this.selected.length > 1
                      ? "Processing iteration... Please wait for this message to be gone."
                      : "Iteration run finished."
                  );
                  try {
                    const res = api.post(`/create-user-history-log`, {
                      ind_id: this.userData.ind_id,
                      email: this.userData.email,
                      screen: 'ICC',
                      action: `Run iteration batch action`,
                      action_description: `Run iteration ${surveyType} to iteration_id ${this.survey.iteration.iteration_id}`,
                    })
                    if(res.status === 200) {
                      console.log('user-history-log API return :', res)
                    }
                  } catch (error) {
                    console.log('Error is :', error)
                  }
                  this.allSelected = false;
                  this.selected = [];
                  this.searchResult("iteration");
                  this.actions = null;
                });
          } catch (error) {
            console.error(error);
          }
        }
      });
    }
      // add primary coach email
      else if (this.actions == 61) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/add-coach-email/${newValue}`, {
            type_of_coach: "primary_coach",
            coach_id: this.coach_email_inp,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Adding primary coach of the selected users... Please wait for this message to be gone."
                : "Successfully add primary coach of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Add primary coach email batch action`,
                action_description: `Add primary coach email for survey_assignment_id ${newValue} with coach_id ${this.coach_email_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
          
        }
      // change primary coach email
      else if (this.actions == 6) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/change-coach-email/${newValue}`, {
            type_of_coach: "primary_coach",
            coach_id: this.coach_email_inp,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Changing primary coach of the selected users... Please wait for this message to be gone."
                : "Successfully changed primary coach of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change primary coach email batch action`,
                action_description: `Change primary coach email for survey_assignment_id ${newValue} with coach_id ${this.coach_email_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
          
        }
    // remove primary coach email
      else if (this.actions == 62) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/remove-coach-email/${newValue}`, {
            type_of_coach: "primary_coach",
            coach_id: null,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Removing primary coach of the selected users... Please wait for this message to be gone."
                : "Successfully remove primary coach of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Remove primary coach email batch action`,
                action_description: `Remove primary coach email for survey_assignment_id ${newValue} with coach_id ${this.coach_email_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
          
        }
    // add secondary coach email
      else if (this.actions == 63) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/add-coach-email/${newValue}`, {
            type_of_coach: "secondary_coach",
            secondary_coach_id: this.secondary_coach_email_inp,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Adding secondary coach of the selected users... Please wait for this message to be gone."
                : "Successfully add secondary coach of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Add secondary coach email batch action`,
                action_description: `Add secondary coach email for survey_assignment_id ${newValue} with secondary_coach_id ${this.secondary_coach_email_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
          
        }
      // change secondary coach email
      else if (this.actions == 64) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/change-coach-email/${newValue}`, {
            type_of_coach: "secondary_coach",
            secondary_coach_id: this.secondary_coach_email_inp,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Changing secondary coach of the selected users... Please wait for this message to be gone."
                : "Successfully change secondary coach of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Change secondary coach email batch action`,
                action_description: `Change secondary coach email for survey_assignment_id ${newValue} with secondary_coach_id ${this.secondary_coach_email_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
          
        }
    // remove secondary coach email
      else if (this.actions == 65) {
        this.actions = null;
        this.toggle = false;
        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");

        await api
          .put(`/survey-assignment/remove-coach-email/${newValue}`, {
            type_of_coach: "secondary_coach",
            secondary_coach_id: null,
            modified_by: this.userData.ind_id,
          })
          .then(() => {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Removing secondary coach of the selected users... Please wait for this message to be gone."
                : "Successfully remove secondary coach of the selected user."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Remove secondary coach email batch action`,
                action_description: `Remove secondary coach email for survey_assignment_id ${newValue} with secondary_coach_id ${this.secondary_coach_email_inp}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
          
        }
        //Calculate selected participants scores
        else if(this.actions == 58){
        this.actions = null;
        this.toggle = false;
        this.surveyAssignmentList = this.selected.map((item) => ({
        survey_assignment_id: item,
      }));
        
        await api
          .post(`/CalculateParticipant`, this.surveyAssignmentList)
          .then(() => {

            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length > 1
                ? "Calculating scores of the selected user(s)... Please wait for this message to be gone."
                : "Successfully calculated scores of the selected user(s)."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Calculate selected participants scores batch action`,
                action_description: `Calculate selected participants scores for survey_assignment_id ${this.surveyAssignmentList}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.searchResult();
          })
          .catch((error) => {
            console.log(error);
          });
          //Generate PDF's
      }else if(this.actions == 59){
        this.actions = null;
        this.toggle = false;
        this.setLoading = true;
        this.surveyAssignmentList = this.selected.map((item) => ({
        survey_assignment_id: item,
      }));
        console.log('frontend id', this.surveyAssignmentList)
        await api
          .post(`/process-generate-pdf-by-survey-assignment-id`, this.surveyAssignmentList)
          .then((res) => {
            console.log(res)
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              this.selected.length >= 1
                ? 'Generated PDF for '+ this.selected.length + ' participant(s).'
                : 'Generated PDF for '+ this.selected.length + ' participant(s).'
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Generate PDF's batch action`,
                action_description: `Generate PDF's for survey_assignment_id ${this.surveyAssignmentList}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.setLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
        //make pdf available
      }else if(this.actions == 60){
        this.actions = null;
        this.toggle = false;
        this.setLoading = true;

        const selectedMappedValue = this.selected.map(
          (u) => u.survey_assignment_id
        );
        const newValue = selectedMappedValue.join(",");
        
        await api
          .put(`/survey-assignment/pdf-available/${newValue}`,{
            modified_by: this.userData.ind_id,
          })
          .then((res) => {
            console.log(res)
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#F47820",
              this.brandData.flash_text_color,
              "Pdf of "+ this.selected.length +" user(s) is now available."
            );
            try {
              const res = api.post(`/create-user-history-log`, {
                ind_id: this.userData.ind_id,
                email: this.userData.email,
                screen: 'ICC',
                action: `Make pdf available batch action`,
                action_description: `Make pdf available for survey_assignment_id ${newValue}`,
              })
              if(res.status === 200) {
                console.log('user-history-log API return :', res)
              }
            } catch (error) {
              console.log('Error is :', error)
            }
            this.allSelected = false;
            this.selected = [];
            this.setLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });

      }
    },

    validateDates: function (a, b) {
      if (a && b) {
        const first = new Date(a);
        const second = new Date(b);
        if (second < first) {
          return false;
        }
      }
      return true;
    },

    readDate(data) {
      var parsedDate = Date.parse(data);

      if (isNaN(data) && !isNaN(parsedDate)) {
        const humanReadableDateTime = new Date(data).toLocaleString();
        return humanReadableDateTime;
      } else {
        return data;
      }
    },

    async searchResult(sorted) {

      if(this.survey.suborg.suborg_id == 0 || this.survey.suborg.suborg_id == undefined) {
        alert('Please select a sub-organization')
        return
      }

      
      if (this.survey.suborg.suborg_id !== undefined && this.survey.suborg.suborg_id !== null) {
        this.storeInCookie('suborg_id', this.survey.suborg.suborg_id);
      }

      if (this.survey.program.program_id !== undefined && this.survey.program.program_id !== null) {
        this.storeInCookie('program_id', this.survey.program.program_id);
      }

      if (this.survey.iteration.iteration_id !== undefined && this.survey.iteration.iteration_id !== null) {
        this.storeInCookie('iteration_id', this.survey.iteration.iteration_id);
      }

      if (this.survey.stream.stream_id !== undefined && this.survey.stream.stream_id !== null) {
        this.storeInCookie('stream_id', this.survey.stream.stream_id);
      }

      this.setLoading = true;
      this.isLoading = true;
      this.firstFetchUserData = [];
      this.secondFetchUserData = [];
      this.selected = [];
      this.allSelected = false;

      if (this.survey.iteration.iteration_id != null && (this.template_id != null || this.template_id > 0)) {
        await api
          .get(
            `/survey-assignments/statistics-by-iterationAndTemplate/${this.survey.iteration.iteration_id}/${this.template_id}/${this.nomineeSurveyTemplateId}`
          )
          .then((res) => {
            // console.log(res.data);
            this.statisticsData = res.data;
            res.data.map((stat) => {
              this.participantsRegisteredPercentage =
                (stat.participants_registered / stat.participants_count) * 100;
              this.participantsSubmittedPercentage =
                (stat.participants_submitted / stat.participants_count) * 100;
              this.participantsEligiblePercentage =
                (stat.participants_eligible / stat.participants_count) * 100;
              this.totalNomineesPercentage =
                stat.nominees_count / stat.participants_count;
              this.totalNomineesSubmittedPercentage =
                stat.nominees_submitted / stat.participants_count;
            });
          });
      } else if (this.survey.iteration.iteration_id != null && (this.template_id == null || this.template_id == 0)) {
        await api
          .get(
            `/survey-assignments/statistics-by-iteration/${this.survey.iteration.iteration_id}`
          )
          .then((res) => {
            // console.log(res.data);
            this.statisticsData = res.data;
            res.data.map((stat) => {
              this.participantsRegisteredPercentage =
                (stat.participants_registered / stat.participants_count) * 100;
              this.participantsSubmittedPercentage =
                (stat.participants_submitted / stat.participants_count) * 100;
              this.participantsEligiblePercentage =
                (stat.participants_eligible / stat.participants_count) * 100;
              this.totalNomineesPercentage =
                stat.nominees_count / stat.participants_count;
              this.totalNomineesSubmittedPercentage =
                stat.nominees_submitted / stat.participants_count;
            });
          });
      }

      if (this.survey.iteration.iteration_id !== null) {
        await api
          .get(`/iteration-log/${this.survey.iteration.iteration_id}`)
          .then((result) => {
            this.iterationLogTableRows = result.data;
          });
      }

      if (this.showIndividualRecords == false) {
        if (
          this.survey.suborg.suborg_id == null &&
          this.survey.program.program_id == null &&
          this.survey.iteration.iteration_id == null
        ) {
          await api
            .get(`individuals/ind_group/org/${this.userData.org_id}`)
            .then((res) => {
              this.firstFetchUserData = res.data;
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                sorted == undefined
                  ? this.firstFetchUserData.length + " result(s) found."
                  : sorted.toString().includes("successfully")
                  ? sorted
                  : this.firstFetchUserData.length + " result(s) found."
              );
            })
            .catch((e) => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                e.message
              );
            });
        } else if (
          this.survey.program.program_id == null &&
          this.survey.iteration.iteration_id == null
        ) {
          await api
            .get(
              `individuals/ind_group/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/`
            )
            .then((res) => {
              this.firstFetchUserData = res.data;
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                sorted == undefined
                  ? this.firstFetchUserData.length + " result(s) found."
                  : sorted.toString().includes("successfully")
                  ? sorted
                  : this.firstFetchUserData.length + " result(s) found."
              );
            })
            .catch((e) => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                e.message
              );
            });
        } else if (
          this.survey.iteration.iteration_id == null &&
          this.survey.stream.stream_id == null
        ) {
          await api
            .get(
              `individuals/ind_group/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}`
            )
            .then((res) => {
              this.firstFetchUserData = res.data;
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                sorted == undefined
                  ? this.firstFetchUserData.length + " result(s) found."
                  : sorted.toString().includes("successfully")
                  ? sorted
                  : this.firstFetchUserData.length + " result(s) found."
              );
            })
            .catch((e) => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                e.message
              );
            });
        } else if (this.survey.stream.stream_id == null) {
          await api
            .get(
              `individuals/ind_group/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}/iteration/${this.survey.iteration.iteration_id}`
            )
            .then((res) => {
              this.firstFetchUserData = res.data;
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                sorted == undefined
                  ? this.firstFetchUserData.length + " result(s) found."
                  : sorted.toString().includes("successfully")
                  ? sorted
                  : this.firstFetchUserData.length + " result(s) found."
              );
            })
            .catch((e) => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                e.message
              );
            });
        } else {
          await api
            .get(
              `individuals/ind_group/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}/iteration/${this.survey.iteration.iteration_id}/stream/${this.survey.stream.stream_id}`
            )
            .then((res) => {
              this.firstFetchUserData = res.data;
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                sorted == undefined
                  ? this.firstFetchUserData.length + " result(s) found."
                  : sorted.toString().includes("successfully")
                  ? sorted
                  : this.firstFetchUserData.length + " result(s) found."
              );
            })
            .catch((e) => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                e.message
              );
            });
        }
      } else {
        // ICC Changes added filter for participant only, nominee only and All for survey assignment records
        if (
          (this.survey.suborg.suborg_id == null || this.survey.suborg.suborg_id == 0) &&
          (this.survey.program.program_id == null || this.survey.program.program_id == 0) &&
          (this.survey.iteration.iteration_id == null || this.survey.iteration.iteration_id) == 0 &&
          (this.survey.stream.stream_id == null || this.survey.stream.stream_id == 0) &&
          (this.selected_is_nomination != null)
        ) {
          await api
            .get(
              `survey-assignments-admin-dashboard/org/${this.userData.org_id}/is_nomination/${this.selected_is_nomination}`
              
            )
            .then((res) => {
              this.secondFetchUserData = res.data;
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                sorted == undefined
                  ? this.secondFetchUserData.length + " result(s) found."
                  : sorted.toString().includes("successfully")
                  ? sorted
                  : this.secondFetchUserData.length + " result(s) found."
              );
            })
            .catch((e) => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#F47820",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                e.message
              );
            });
        } else if (
          (this.survey.suborg.suborg_id != null) &&
          (this.survey.program.program_id == null || this.survey.program.program_id == 0) &&
          (this.survey.iteration.iteration_id == null || this.survey.iteration.iteration_id == 0) &&
          (this.survey.stream.stream_id == null || this.survey.stream.stream_id == 0) &&
          (this.selected_is_nomination != null)
        ) {
          if ((this.template_id == null || this.template_id == 0) || this.template_id > 0) {
            await api
              .get(
                `survey-assignments/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/is_nomination/${this.selected_is_nomination}`
              )
              .then((res) => {
                this.secondFetchUserData = res.data;
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  sorted == undefined
                    ? this.secondFetchUserData.length + " result(s) found."
                    : sorted.toString().includes("successfully")
                    ? sorted
                    : this.secondFetchUserData.length + " result(s) found."
                );
              })
              .catch((e) => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  e.message
                );
              });
          } else {
            api
              .get(
                `survey-assignments-survey-template/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/template/${this.template_id}`
              )

              .then((res) => {
                this.secondFetchUserData = res.data;
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  sorted == undefined
                    ? this.secondFetchUserData.length + " result(s) found."
                    : sorted.toString().includes("successfully")
                    ? sorted
                    : this.secondFetchUserData.length + " result(s) found."
                );
              })
              .catch((e) => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  e.message
                );
              });
          }
        } else if (
          (this.survey.suborg.suborg_id != null) &&
          (this.survey.program.program_id != null) &&
          (this.survey.iteration.iteration_id == null || this.survey.iteration.iteration_id == 0) &&
          (this.survey.stream.stream_id == null || this.survey.stream.stream_id == 0) &&
          (this.selected_is_nomination != null)
        ) {
          if (this.template_id == null || this.template_id == 0) {
            await api
              .get(
                `survey-assignments/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}/is_nomination/${this.selected_is_nomination}`
              )
              .then((res) => {
                this.secondFetchUserData = res.data;
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  sorted == undefined
                    ? this.secondFetchUserData.length + " result(s) found."
                    : sorted.toString().includes("successfully")
                    ? sorted
                    : this.secondFetchUserData.length + " result(s) found."
                );
              })
              .catch((e) => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  e.message
                );
              });
          } else {
            await api
              .get(
                `survey-assignments-survey-template/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}/template/${this.template_id}`
              )
              .then((res) => {
                this.secondFetchUserData = res.data;
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  sorted == undefined
                    ? this.secondFetchUserData.length + " result(s) found."
                    : sorted.toString().includes("successfully")
                    ? sorted
                    : this.secondFetchUserData.length + " result(s) found."
                );
              })
              .catch((e) => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  e.message
                );
              });
          }
        } else if (
          (this.survey.suborg.suborg_id != null) &&
          (this.survey.program.program_id != null) &&
          (this.survey.iteration.iteration_id != null) &&
          (this.survey.stream.stream_id == null || this.survey.stream.stream_id == 0) &&
          (this.selected_is_nomination != null)
        ) {
          if (this.template_id == null || this.template_id == 0) {
            await api
              .get(
                `survey-assignments/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}/iteration/${this.survey.iteration.iteration_id}/is_nomination/${this.selected_is_nomination}`
              )
              .then((res) => {
                console.log(res.data)
                this.secondFetchUserData = res.data;
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  sorted == undefined
                    ? this.secondFetchUserData.length + " result(s) found."
                    : sorted.toString().includes("successfully")
                    ? sorted
                    : this.secondFetchUserData.length + " result(s) found."
                );
              })
              .catch((e) => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  e.message
                );
              });
          } else {
            await api
              .get(
                `survey-assignments-survey-template/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}/iteration/${this.survey.iteration.iteration_id}/template/${this.template_id}`
              )
              .then((res) => {
                this.secondFetchUserData = res.data;
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  sorted == undefined
                    ? this.secondFetchUserData.length + " result(s) found."
                    : sorted.toString().includes("successfully")
                    ? sorted
                    : sorted.toString().includes("iteration")
                    ? "Iteration run successful"
                    : this.secondFetchUserData.length + " result(s) found."
                );
              })
              .catch((e) => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  e.message
                );
              });
          }
        } else if (
          this.survey.suborg.suborg_id != null &&
          this.survey.program.program_id != null &&
          this.survey.iteration.iteration_id != null &&
          this.survey.stream.stream_id != null &&
          this.selected_is_nomination != null
        ) {
          if (this.template_id == null || this.template_id == 0) {
            console.log('e2 yun', this.survey.stream.stream_id)
            await api
              .get(
                `survey-assignments/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}/iteration/${this.survey.iteration.iteration_id}/stream/${this.survey.stream.stream_id}/is_nomination/${this.selected_is_nomination}`
              )
              .then((res) => {
                this.secondFetchUserData = res.data;
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  sorted == undefined
                    ? this.secondFetchUserData.length + " result(s) found."
                    : sorted.toString().includes("successfully")
                    ? sorted
                    : this.secondFetchUserData.length + " result(s) found."
                );
              })
              .catch((e) => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  e.message
                );
              });
          } else {
            await api
              .get(
                `survey-assignments-survey-template/org/${this.userData.org_id}/suborg/${this.survey.suborg.suborg_id}/program/${this.survey.program.program_id}/iteration/${this.survey.iteration.iteration_id}/stream/${this.survey.stream.stream_id}/template/${this.template_id}`
              )
              .then((res) => {
                this.secondFetchUserData = res.data;
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  sorted == undefined
                    ? this.secondFetchUserData.length + " result(s) found."
                    : sorted.toString().includes("successfully")
                    ? sorted
                    : this.secondFetchUserData.length + " result(s) found."
                );
              })
              .catch((e) => {
                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#F47820",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  e.message
                );
              });
          }
        }


      }
      this.toggle = false;
      this.actions = null;
      this.survey.survey_template_id = null;
      this.setLoading = false;
      this.isLoading = false

    },

    clearData() {
      this.firstFetchUserData = [];
      this.secondFetchUserData = [];
      this.selected = [];
      this.allSelected = false;
      this.toggle = false;
      this.actions = null;
      this.surveyTemplate = null;
      this.sortBy = "";
      this.tags = null;
      this.survey.action_program_id = null;
      this.survey.action_iteration_id = null;
      this.survey.action_stream_id = null;
      this.survey.group_id = null;
      this.survey.survey_template_id = null;
      this.suborg_inp = null;
      this.program_inp = null;
      this.iteration_inp = null;
      this.stream_inp = null;
      this.group_inp = null;
      this.coach_email_inp = null;
      this.secondary_coach_email_inp = null;
      this.dates.deadline_final = "";
      this.dates.launch = "";
      this.dates.reminder = "";
      this.dates.coach_report_start = "";
      this.dates.coach_report_end = "";
      this.dates.participant_report_start = "";
      this.dates.initial_deadline = "";
      this.statisticsData = [];
      this.participantsRegisteredPercentage = 0;
      this.participantsSubmittedPercentage = 0;
      this.participantsEligiblePercentage = 0;
      this.totalNomineesPercentage = 0;
      this.totalNomineesSubmittedPercentage = 0;
      this.nomineeSurveyTemplateId = 0;
      this.iterationLogTableRows = null;
    },

    clearInput() {
      this.tags = null;
      this.survey.action_program_id = null;
      this.survey.action_iteration_id = null;
      this.survey.action_stream_id = null;
      this.survey.action_tag = null;
      this.survey.group_id = null;
      this.survey.survey_template_id = null;
      this.suborg_inp = null;
      this.program_inp = null;
      this.iteration_inp = null;
      this.stream_inp = null;
      this.group_inp = null;
      this.coach_email_inp = null;
      this.secondary_coach_email_inp = null;
      this.dates.deadline_final = "";
      this.dates.launch = "";
      this.dates.reminder = "";
      this.dates.coach_report_start = "";
      this.dates.coach_report_end = "";
      this.dates.participant_report_start = "";
      this.dates.initial_deadline = "";
    },

    userSelectAll() {
      if (this.allSelected) {
        const selected = this.firstFetchUserData.map((u) => u);
        this.selected = selected;
      } else {
        this.selected = [];
      }
    },

    surveySelectAll() {
      if (this.allSelected) {
        const selected = this.secondFetchUserData.map(
          (u) => u.survey_assignment_id
        );
        this.selected = selected;
      } else {
        this.selected = [];
      }
    },
  },
  computed: {
    filteredData2() {
      return this.filteredSurveyAssignmentData.map((item) => {
        const filteredItem = {};
        this.displayedHeaders.forEach((header) => {
          filteredItem[header.key] = item[header.key];
        });
        return filteredItem;
      });
    },
    showHeaders () {
      return this.columnHideShowHeader.filter(s => this.selectedHeaders.includes(s));
    },

    filteredRemoveTags: function () {
      return this.mapTags.filter(function (el) {
        return el !== undefined;
      }, this);
    },

    mapTags: function () {
      return this.userTags
        .sort((a, b) => a.tag_type.localeCompare(b.tag_type))
        .map(function (el) {
          if (this.userData.org_id === el.org_id) {
            return { value: el.tag_id, label: el.tag_type };
          }
        }, this);
    },

    filteredTags: function () {
      return this.userTags
        .sort((a, b) => a.tag_type.localeCompare(b.tag_type))
        .filter(function (el) {
          if (this.userData.org_id != "") {
            return el.org_id == this.userData.org_id;
          }
        }, this);
    },

    filteredSubOrg: function () {
      // Convert this.userData.suborgs from a comma-delimited string to an array of integers
      const suborgsArray = this.userData.suborgs.split(',').map(Number);

      // Ensure 'Select' item is handled correctly
      const selectItem = this.userSubOrg.find(el => el.suborg_id === 0);
      let restOfSubOrgs = this.userSubOrg.filter(el => el.suborg_id !== 0);

      // Sort the suborgs excluding the 'Select' item
      restOfSubOrgs.sort((a, b) => a.suborg_name.localeCompare(b.suborg_name));

      // Filter based on the suborgsArray, if applicable
      if (this.userData.suborgs !== "") {
        restOfSubOrgs = restOfSubOrgs.filter(el => suborgsArray.includes(el.suborg_id));
      }

      // Re-introduce the 'Select' item at the start if it exists
      if (selectItem) {
        restOfSubOrgs.unshift(selectItem);
      }

      return restOfSubOrgs;
    },

    mapSubOrg: function () {
      return this.userSubOrg.map(function (el) {
        if (this.userData.org_id === el.org_id) {
          return { value: el.suborg_id, label: el.suborg_name };
        }
      }, this);
    },

    filteredSubOrgActions: function () {
      return this.mapSubOrg.filter(
        function (el) {
          return el !== undefined;
        },
        this,
        console.log(this.statisticsData)
      );
    },

    mapUserProgram: function () {
      return this.userPrograms.map(function (el) {
        if (
          this.userData.org_id === el.org_id &&
          this.survey.suborg.suborg_id == el.suborg_id
        ) {
          return { value: el.program_id, label: el.program_name };
        }
      }, this);
    },

    filteredProgramActions: function () {
      return this.mapUserProgram.filter(function (el) {
        return el !== undefined;
      }, this);
    },

    filteredProgram: function () {
      // Create a 'Select Program' item with program_id of 0 and suborg_id of 0
      const selectProgramItem = { program_id: 0, program_name: 'Select Program', org_id: this.userData.org_id, suborg_id: this.survey.suborg.suborg_id };

      // Clone the userPrograms array to avoid modifying the original
      const clonedUserPrograms = [...this.userPrograms];

      // Sort the array
      const sortedPrograms = clonedUserPrograms.sort((a, b) => a.program_name.localeCompare(b.program_name));

      // Push 'Select Program' item into the sorted array as the topmost option
      const finalPrograms = [selectProgramItem, ...sortedPrograms];

      // Filter based on userData.org_id, survey.suborg.suborg_id, and el.org_id
      return this.userData.org_id !== ""
        ? finalPrograms.filter(el => el.org_id == this.userData.org_id && el.suborg_id == this.survey.suborg.suborg_id)
        : finalPrograms;
    },

    mapIteration: function () {
      return this.userIterations.map(function (el) {
        if (
          this.userData.org_id === el.org_id &&
          this.survey.suborg.suborg_id == el.suborg_id &&
          this.survey.program.program_id == el.program_id
        ) {
          return { value: el.iteration_id, label: el.iteration_name };
        }
      }, this);
    },

    mapIterationMultiSelect: function () {
      return this.userIterations.map(function (el) {
        if (
          this.userData.org_id === el.org_id &&
          this.survey.suborg.suborg_id == el.suborg_id &&
          this.survey.action_program_id == el.program_id
        ) {
          return { value: el.iteration_id, label: el.iteration_name };
        }
      }, this);
    },

    filteredIterationActions: function () {
      return this.mapIteration.filter(function (el) {
        return el !== undefined;
      }, this);
    },

    filteredIterationMultiSelectActions: function () {
      return this.mapIterationMultiSelect.filter(function (el) {
        return el !== undefined;
      }, this);
    },

    filteredIteration: function () {
      // Create a 'Select Iteration' item with iteration_id of 0
      const selectIterationItem = { iteration_id: 0, iteration_name: 'Select Iteration', org_id: this.userData.org_id, suborg_id: this.survey.suborg.suborg_id, program_id: this.survey.program.program_id };

      // Clone the userIterations array to avoid modifying the original
      const clonedUserIterations = [...this.userIterations];

      // Sort the array
      const sortedIterations = clonedUserIterations.sort((a, b) => a.iteration_name.localeCompare(b.iteration_name));

      // Push 'Select Iteration' item into the sorted array as the topmost option
      const finalIterations = [selectIterationItem, ...sortedIterations];

      // Filter based on userData.org_id, survey.suborg.suborg_id, and survey.program.program_id
      return this.userData.org_id !== ""
        ? finalIterations.filter(el => el.org_id == this.userData.org_id && el.suborg_id == this.survey.suborg.suborg_id && el.program_id == this.survey.program.program_id)
        : finalIterations;
    },

    mapStream: function () {
      return this.userStreams.map(function (el) {
        if (
          this.userData.org_id === el.org_id &&
          this.survey.suborg.suborg_id == el.suborg_id &&
          this.survey.program.program_id == el.program_id &&
          this.survey.iteration.iteration_id == el.iteration_id
        ) {
          return { value: el.stream_id, label: el.stream_name };
        }
      }, this);
    },

    filteredStreamActions: function () {
      return this.mapStream.filter(function (el) {
        return el !== undefined;
      }, this);
    },

    filteredStream: function () {
      // Create a 'Select Stream' item with stream_id of 0
      const selectStreamItem = { stream_id: 0, stream_name: 'Select Stream', org_id: this.userData.org_id, suborg_id: this.survey.suborg.suborg_id, program_id: this.survey.program.program_id, iteration_id: this.survey.iteration.iteration_id };

      // Clone the userStreams array to avoid modifying the original
      const clonedUserStreams = [...this.userStreams];

      // Sort the array
      const sortedStreams = clonedUserStreams.sort((a, b) => a.stream_name.localeCompare(b.stream_name));

      // Push 'Select Stream' item into the sorted array as the topmost option
      const finalStreams = [selectStreamItem, ...sortedStreams];

      // Filter based on userData.org_id, survey.suborg.suborg_id, survey.program.program_id, and survey.iteration.iteration_id
      return this.userData.org_id !== ""
        ? finalStreams.filter(el => el.org_id == this.userData.org_id && el.suborg_id == this.survey.suborg.suborg_id && el.program_id == this.survey.program.program_id && el.iteration_id == this.survey.iteration.iteration_id)
        : finalStreams;
    },

    mapUserGroup: function () {
      return this.userGroups.map(function (el) {
        if (
          this.userData.org_id === el.org_id &&
          this.survey.suborg.suborg_id == el.suborg_id &&
          this.survey.program.program_id == el.program_id &&
          this.survey.iteration.iteration_id == el.iteration_id &&
          this.survey.stream.stream_id == el.stream_id
        ) {
          return { value: el.group_id, label: el.group_name };
        }
      }, this);
    },

    mapUserGroupMultiSelect: function () {
      return this.userGroups.map(function (el) {
        if (
          this.userData.org_id === el.org_id &&
          this.survey.suborg.suborg_id == el.suborg_id &&
          this.survey.program.program_id == el.program_id &&
          this.survey.iteration.iteration_id == el.iteration_id &&
          this.survey.action_stream_id == el.stream_id
        ) {
          return { value: el.group_id, label: el.group_name };
        }
      }, this);
    },

    filteredGroupActions: function () {
      return this.mapUserGroup.filter(function (el) {
        return el !== undefined;
      }, this);
    },

    filteredGroupMultiSelectActions: function () {
      return this.mapUserGroupMultiSelect.filter(function (el) {
        return el !== undefined;
      }, this);
    },

    mapCoachEmail: function () {
      return this.coachUsers.map(function (el) {
        if (
          el.suborgs != 0 &&
          el.suborgs != null &&
          el.suborgs != undefined &&
          el.suborgs != ""
        ) {
          var newValueSuborg = el.suborgs.split(", ");
          this.newNameSuborg = newValueSuborg.map(
            (r) => this.userSubOrg.find((f) => f.suborg_id == r).suborg_id
          );
        }

        var joinSuborg = this.newNameSuborg.join(", ");

        if (joinSuborg.includes(this.survey.suborg.suborg_id)) {
          return { value: el.ind_id, label: el.email };
        }
      }, this);
    },

    filteredCoachEmailActions: function () {
      return this.mapCoachEmail.filter(function (el) {
        return el !== undefined;
      }, this);
    },

    filteredSurveyTemplate: function () {
      // Create a 'Select Survey Template' item with survey_template_id of 0
      const selectSurveyTemplateItem = { survey_template_id: 0, survey_template_name: 'Select Survey Template', org_id: this.userData.org_id, suborg_id: this.survey.suborg.suborg_id };

      // Clone the userSurveyTemplate array to avoid modifying the original
      const clonedUserSurveyTemplate = [...this.userSurveyTemplate];

      // Sort the array
      const sortedSurveyTemplates = clonedUserSurveyTemplate.sort((a, b) => a.survey_template_name.localeCompare(b.survey_template_name));

      // Push 'Select Survey Template' item into the sorted array as the topmost option
      const finalSurveyTemplates = [selectSurveyTemplateItem, ...sortedSurveyTemplates];

      // Filter based on userData.org_id and survey.suborg.suborg_id
      const filteredTemplates = this.userData.org_id !== ""
        ? finalSurveyTemplates.filter(el => el.org_id == this.userData.org_id && el.suborg_id == this.survey.suborg.suborg_id)
        : finalSurveyTemplates;

      // Additional filtering based on selected_is_nomination value
      if (this.selected_is_nomination === 0) {
        // Show templates with nominee_survey_template_id not null
        return filteredTemplates.filter(el => el.nominee_survey_template_id !== null);
      } else if (this.selected_is_nomination === 1) {
        // Show templates with nominee_survey_template_id null
        return filteredTemplates.filter(el => el.nominee_survey_template_id === null);
      } else {
        // For value 'All', return all templates
        return filteredTemplates;
      }
    },

    filteredBatchActions: function () {
      return this.batchActions
        .sort((a, b) => a.batch_action_type.localeCompare(b.batch_action_type))
        .filter(function (el) {
          if ((this.template_id != null || this.template_id > 0) && this.survey.iteration.iteration_id != null) {
            return (
              el.batch_action_id != 19 &&
              el.batch_action_id != 21 &&
              el.batch_action_id != 22
            );
          } else {
            return (
              el.batch_action_id != 19 &&
              el.batch_action_id != 21 &&
              el.batch_action_id != 22 &&
              el.batch_action_id != 25
            );
          }
        }, this);
    },

    filteredAssignGroup: function () {
      return this.batchActions2
        .sort((a, b) => a.batch_action_type.localeCompare(b.batch_action_type))
        .filter(function (el) {
          if (
            this.survey.program.program_id != null &&
            this.survey.iteration.iteration_id != null
          ) {
            return el.batch_action_id == 22;
          }
        }, this);
    },

    filteredUserSurveyTemplate: function () {
      return this.userSurveyTemplate
        .sort((a, b) =>
          a.survey_template_name.localeCompare(b.survey_template_name)
        )
        .filter(function (el) {
          if (
            el.nominee_survey_template_id != null &&
            el.org_id == this.userData.org_id &&
            el.suborg_id == this.survey.suborg.suborg_id
          ) {
            return el;
          }
        }, this);
    },

    disableGoButton() {
      if (this.tags != null && this.tags != "") {
        return true;
      } else if (
        this.survey.action_program_id != null &&
        this.survey.action_program_id != ""
      ) {
        return true;
      } else if (
        this.survey.action_iteration_id != null &&
        this.survey.action_iteration_id != ""
      ) {
        return true;
      } else if (
        this.survey.action_stream_id != null &&
        this.survey.action_stream_id != ""
      ) {
        return true;
      } else if (this.survey.group_id != null && this.survey.group_id != "") {
        return true;
      } else if (
        this.survey.survey_template_id != null &&
        this.survey.survey_template_id != ""
      ) {
        return true;
      } else if (this.suborg_inp != null && this.suborg_inp != "") {
        return true;
      } else if (this.program_inp != null && this.program_inp != "") {
        return true;
      } else if (this.iteration_inp != null && this.iteration_inp != "") {
        return true;
      } else if (this.stream_inp != null && this.stream_inp != "") {
        return true;
      } else if (this.group_inp != null && this.group_inp != "") {
        return true;
      } else if (this.coach_email_inp != null && this.coach_email_inp != "") {
        return true;
      }else if (this.secondary_coach_email_inp != null && this.secondary_coach_email_inp != "") {
        return true;
      } else if (
        this.dates.deadline_final != null &&
        this.dates.deadline_final != ""
      ) {
        return true;
      } else if (this.dates.launch != null && this.dates.launch != "") {
        return true;
      } else if (this.dates.reminder != null && this.dates.reminder != "") {
        return true;
      } else if (
        this.dates.coach_report_start != null &&
        this.dates.coach_report_start != ""
      ) {
        return true;
      } else if (
        this.dates.coach_report_end != null &&
        this.dates.coach_report_end != ""
      ) {
        return true;
      } else if (
        this.dates.participant_report_start != null &&
        this.dates.participant_report_start != ""
      ) {
        return true;
      } else if (
        this.dates.initial_deadline != null &&
        this.dates.initial_deadline != ""
      ) {
        return true;
      } else if (
        this.survey.action_tag != null &&
        this.survey.action_tag != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    sortedProperties() {
      const direction = this.sortDirection;
      const head = this.sortBy;
      return this.firstFetchUserData.sort(this.dynamicSort(head, direction));
    },

    table2SortedProperties() {
      const direction = this.sortDirection;
      const head = this.sortBy;
      return this.secondFetchUserData.sort(this.dynamicSort(head, direction));
    },

    filteredSurveyAssignmentData() {
      return this.secondFetchUserData.filter(d => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      }).map(d => {
        // Check if tag_names is not null before splitting
        if (d.tag_names !== null && typeof d.tag_names === 'string') {
          // Sort the comma-delimited items in tag_names alphabetically
          d.tag_names = d.tag_names.split(',').map(item => item.trim()).sort().join(', ');
        }
        return d;
      });
    },

    surveyHeaders() {
      // ... your existing headers configuration ...

      // Modify headers to include 'key' property for filtering
      return this.survey_headers_data.filter(s => this.selectedHeaders.includes(s));
    },

    filteredUserData() {
      return this.firstFetchUserData.filter(d => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      })
    },

    userHeaders() {
      // ... your existing headers configuration ...

      // Modify headers to include 'key' property for filtering
      return this.user_headers_data.map(header => ({ ...header, key: header.key || header.value }));
    },

  },
  watch: {
    selectedHeaders: {
      handler(newSelectedHeaders) {
        this.displayedHeaders = this.survey_headers_data.filter((header) => newSelectedHeaders.includes(header));
      },
      immediate: true,
    },
    filteredSurveyAssignmentData() {
      this.handleOptionsUpdate();
    },
  },
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th {
  background: none !important;
}
</style>
<style scoped>
.icon-background1 {
  color: red;
  /* background-color: black; */
}
.fa-star {
  color: green;
}

.label-btn {
  color: #0e5071;
  background-color: #fff;
}

.label-div {
  border: 1px solid #0e5071;
  background-color: #0e5071;
  color: #fff;
  display: flex;
  align-items: center;
}
#batchAction {
  height: 10%;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  border-color: grey;
  margin: 30px 0px;
}

.input {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
  font-family: Arial, Helvetica, sans-serif;
}

.box {
  border: 1px solid #ccc;
  border-top: 4px solid rgb(185, 22, 10);
  text-align: center;
  font: -webkit-control;
  margin: 0 10px;
  min-height: 400px;
  padding: 10px;
  max-width: 250px;
  min-height: 620px;
}

.boxReports {
  border: 1px solid #ccc;
  /* border-top: 4px solid rgb(185, 22, 10); */
  text-align: left;
  font: -webkit-control;
  margin: 0 10px;
  min-height: 10px;
  max-width: 100%;
  min-width: 75vw;
}

.boxStatistics {
  border: 1px solid #ccc;
  /* border-top: 4px solid rgb(185, 22, 10); */
  text-align: left;
  font: -webkit-control;
  margin: 0 10px;
  min-height: 10vh;
  max-width: 100%;
  min-width: 37.5vw;
}

.label1 {
  top: 0px;
  left: 0px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
  font-family: Arial, Helvetica, sans-serif;
}

.input1 {
  width: 85%;
  outline: 0;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
}
.inputTemplate {
  width: 60%;
  outline: 0;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
}

#selectTag {
  width: 90%;
}

.batch-action-input {
  width: 30%;
  outline: 0;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
}

#selectBatchAction {
  width: 85%;
  outline: 0;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
}

.batch-action-input2 {
  width: 85%;
  outline: 0;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
}

#iteration-log-table {
  border-collapse: collapse;
  width: 100%;
}

#iteration-log-table th,
#iteration-log-table td {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}

#iteration-log-table th {
  background-color: #90d1f1;
  position: sticky;
  top: 0;
}

#iteration-log-table-container {
  height: 200px;
  overflow-y: scroll;
  margin: 0 0 20px 0;
}

#table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
}

#table td,
#table th {
  border: 1px solid #ddd;
  padding: 8px;
  /* word-break: word-wrap; */
}

#table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover {
  background-color: #ddd;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  /* text-align: left; */
  background-color: #0e5071;
  color: white;
}

#table td {
  /* text-align: left; */
  vertical-align: middle;
}

#table2 {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

#table2 td,
#table2 th {
  border: 1px solid #ddd;
  padding: 6px;
  height: auto;
}

#table2 tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table2 tr:hover {
  background-color: #ddd;
}

#table2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  /* text-align: left; */
  background-color: #0e5071;
  color: white;
  word-break: word-wrap;
  font-size: 0.7vw;
}

#table2 td {
  /* text-align: left; */
  vertical-align: middle;
  word-break: word-wrap;
  font-size: 0.7vw;
}

.td-checkbox {
  text-align: center !important;
}

.active {
  color: #f47820;
}

.statistics-label {
  margin: 0;
}

.search-btn {
  width: 20%;
}

.main-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.actions-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 30px;
}

.batchAction-btn {
  margin-left: 20px;
  width: 20%;
}

.multiselect-input {
  --ms-tag-font-size: 1vw;
  --ms-px: 0.5rem;
}

@media only screen and (min-width: 280px) and (max-width: 900px) {
  .main-div {
    font-size: 2.5vw;
    background: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
  }

  .search-btn {
    width: 20%;
  }
  #batchAction {
    height: 10%;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    border-color: grey;
    margin: 30px 0px;
    font-size: 1.5vw;
  }

  .input {
    width: 80%;
    outline: 0;
    border-width: 0 0 1px;
    border-color: grey;
    padding: 2px 5px;
    margin: 10px 0px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .multiselect {
    font-size: 1.5vw;
  }

  .batch-action-input2 {
    font-size: 1.5vw;
  }
  .input1 {
    font-size: 1.5vw;
  }

  #table2 {
    font-size: 2vw;
    border-collapse: collapse;
    word-break: break-word;
  }

  #table {
    font-size: 1.5vw;
  }

  .survey-data-table-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    margin-top: 30px;
    width: 100%;
    font-size: 1vw;
  }
  .batch-action-input {
    width: 30%;
    outline: 0;
    border-color: grey;
    padding: 2px 5px;
    margin: 10px 0px;
  }

  .multiselect-input {
    --ms-tag-font-size: 0.6vw;
    --ms-px: 0.3rem;
  }

  #selectBatchAction[data-v-119a4ef4] {
    font-size: 1.5vw;
  }
  .batch-action-desc {
    font-size: 1.5vw;
  }
}
input, select, option, textarea {
  border: 1px solid grey;
}
.survey-data-table-container{
  width: 100%;
}
.survey-custom-data-table {
  border: 1px solid rgb(200, 200, 200);
}

.survey-custom-data-table tr {
  /* display: flex;
  flex-direction: row; */
  width: 3vw;
}
.survey-custom-data-table th {
  color: white;
  white-space: nowrap;
}

.survey-custom-data-table :deep(table) > thead {
  background-color: #0e5071;
}

.user-data-table-container{
  display: flex;
  justify-content: center;
}
.user-custom-data-table {
  margin-top: 50px;
  border: 1px solid rgb(200, 200, 200);
  width: 90%;
  font-size: 0.9vw;
  border-collapse: collapse;
  word-break: break-word;
}

.user-custom-data-table tr {
  display: flex;
  flex-direction: row;
  width: 12vw;
}
.user-custom-data-table th {
  color: white;
}

.user-custom-data-table tbody tr:nth-child(odd) {
  background-color: #000;
}
.user-custom-data-table :deep(table) > thead {
  background-color: #0e5071;
}
.title-container{
  height: 10%;
  position: relative;
  margin: 10px 0 0 0;
}

  .row-start {
    margin-top: 20px;
  }

  .gospi-form {
    border: 0.5px solid grey;
    padding: 15px;
    border-radius: 20px;
  }

  .box-statistics {
    border: 0.5px solid grey;
    padding: 30px;
    border-radius: 20px;
  }
  
  .v-row {
    margin-top: -20px;
    margin-bottom: -50px;
  }
  .v-radio-group {
    margin-left: 6px;
  }
  .v-checkbox {
    margin-top: -30px;
    margin-bottom: 20px;
  } 

  .column-header-filter{
  width: 100%;
  padding: 0;
}

.body-form-container{
  box-shadow: none;
  margin-top: 25px;
}

.v-data-table-header {
  white-space: nowrap;
}
.v-data-table-header__icon {
  margin: 10px;
  font-size: medium;
}
.header-th{
  background-color: #0e5071 !important;
}
.column-header-filter .v-input__append-inner {
  display: none !important;
}

.menu-buttons {
  font-weight: bold;
}

.participant-details-link{
  text-decoration: none;
}

.all-data-checkbox{
  margin-left: 10px;
}
.excel-download-btn{
  padding: 0;
}
</style>
